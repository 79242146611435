import { ReactElement } from "react";
import type { Country } from 'utils/types';
import CountryFlag from "@/shared/ui/CountryFlag";
import * as SC from './styled';

type Props = {
  country: Country;
  AdditionalInfoSection?: ReactElement;
}

function CountryCard({ country, AdditionalInfoSection }: Props) {

  return (
    <SC.Wrapper>
      <div>
        <CountryFlag width={56} height={42} name={country.isoName2} />
      </div>
      <SC.CountryName>{country.country}</SC.CountryName>
      {AdditionalInfoSection}
    </SC.Wrapper>
  )
}

export default CountryCard