import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const RegionName = styled.p`
  margin: 17px 0 5px;
  font-size: 24px;
  font-weight: 600;
`