import styled from "styled-components";
import { Container } from "@/shared/ui/styled";

export const Wrapper = styled(Container)`
  padding: 0;
`

export const Title = styled.p`
  margin: 0;
  color: ${(props) => props.theme.primaryText};
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  text-align: center;
`

export const SideSectionWrapper = styled.div`
  position: absolute;
  left: calc(100% - 10px);
  top: 485px;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 1630px) {
    display: none;
  }
`

export const QRCodeWrapper = styled.div`
  margin: 25px 0;
`

export const ButtonsWrapper = styled.div`
  > div {
    gap: 10px;
  }

  button {
    padding: 2px;
  }

  svg {
    width: 90px;
    height: 30px;
  }
`