'use client';

import React, { useCallback, useEffect, useState } from 'react';
import { useTranslations } from 'next-intl';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import { useQuery } from '@tanstack/react-query';
import api from '@/api';
import { Bundle, Country, Region } from '@/utils/types';
import { createQueryString } from '@/utils/common';
import { useWindowSize } from '@/context/WindowSizeContext';
import { useAnalyticsContext } from '@/context/AnalyticsContext';
import LocaleLink from '@/entities/LocaleLink';
import CoverageCountriesOpenModalButton from '@/entities/CoverageCountriesOpenModalButton';
import { BundleInfo } from '@/entities/bundle';
import BaseRegionCard from '@/entities/RegionCard';
import { BASE_STORAGE_URL, SectionIDS } from '@/shared/constants';
import { scrollToId } from '@/shared/lib';
import Loader from '@/shared/ui/Loader';
import CountryFlag from '@/shared/ui/CountryFlag';
import { NoMatchesText } from '@/shared/ui/styled';
import Button from '@/shared/ui/Button';
import * as SC from './styled';

type Props = { countries: Country[], regions: Region[], filter: string, isCountriesLoading: boolean, showBundleCoverageCountries: (bundle: Bundle) => void };

function RegionalEsim({ countries, regions, filter, isCountriesLoading, showBundleCoverageCountries }: Props) {
  const router = useRouter();
  const { sendSafeGtagEvent, sendSafeMixpanelEvent } = useAnalyticsContext();
  const searchParams = useSearchParams();
  const pathname = usePathname();
  const { isMobile } = useWindowSize();
  const region = searchParams.get('regional');
  const t = useTranslations();
  const [filteredRegions, setFilteredRegions] = useState<Region[]>(regions);
  const selectedRegion = regions.find((el) => el.name.toLowerCase() === region) || filteredRegions?.[0]

  const changeRegion = useCallback((_region: Region | null) => {
    sendSafeGtagEvent('click_choose_country', { region: _region?.name })
    sendSafeMixpanelEvent('track', 'click_choose_country', { region: _region?.name })
    const currentSearchParams = new URLSearchParams(searchParams.toString());
    currentSearchParams.delete("regional");
    const queryString = _region ? createQueryString('regional', _region?.name.toLowerCase(), currentSearchParams) : currentSearchParams.toString();
    router.push(`${pathname}?${queryString}`, {
      scroll: false,
    });

  }, [router, searchParams, pathname, sendSafeGtagEvent, sendSafeMixpanelEvent]);

  useEffect(() => {
    if (isMobile && region) {
      scrollToId(SectionIDS.InfoSection, 65)
    }
  }, [isMobile, region])

  useEffect(() => {
    const newBundlesFilteredByCountries = regions.filter(({ id }) =>
      countries.find(({ regionlist }) => regionlist.includes(id))
    );

    if (selectedRegion) {
      const existingActiveBundleInNewBundlesList = newBundlesFilteredByCountries.find(
        ({ name }) => name === selectedRegion?.name
      );

      if (!existingActiveBundleInNewBundlesList) {
        changeRegion(null)
      };
    }

    setFilteredRegions(newBundlesFilteredByCountries);
  }, [countries, changeRegion, regions, selectedRegion]);

  return (
    <SC.Wrapper>
      <SC.ContentWrapper>
        <SC.LeftSideWrapper>
          <SC.RegionsWrapper>
            {!isCountriesLoading &&
              filteredRegions.map((regionData) => (
                <SC.RegionCard $selected={regionData.id === selectedRegion?.id} key={regionData.name} onClick={() => changeRegion(regionData)}>
                  <div>
                    <CountryFlag width={56} height={42} src={`${BASE_STORAGE_URL}130x80/${regionData.name}350.jpg`} />
                  </div>
                  <div>
                    <div>{regionData.name}</div>
                  </div>
                </SC.RegionCard>
              ))}
          </SC.RegionsWrapper>
        </SC.LeftSideWrapper>
        {selectedRegion && (
          <SC.InfoSection id={SectionIDS.InfoSection}>
            <RegionCard loading={isCountriesLoading} region={selectedRegion} showBundleCoverageCountries={showBundleCoverageCountries} />
          </SC.InfoSection>
        )}
      </SC.ContentWrapper>
      {!filteredRegions.length && filter && (
        <NoMatchesText>{t('regions_not_found_by_filter', { filter })}</NoMatchesText>
      )}
    </SC.Wrapper>
  );
}

type RegionCardProps = {
  region: Region;
  loading: boolean;
  showBundleCoverageCountries: (bundle: Bundle) => void
}

function RegionCard({ region, loading, showBundleCoverageCountries }: RegionCardProps) {
  const t = useTranslations()
  const { sendSafeGtagEvent, sendSafeMixpanelEvent, sendSafeFbqEvent } = useAnalyticsContext();
  const [selectedBundle, setSelectedBundle] = useState<Bundle>();

  const {
    data: regionById,
    isLoading: isRegionByIdLoading,
  } = useQuery(
    ['region-by-id', region.id],
    async () => {
      const { data } = await api.profiles.getRegionById(region.id);
      return data?.data.region;
    },
    { enabled: Boolean(region.id) }
  );

  const search = new URLSearchParams({
    country: selectedBundle?.isoName2 || '',
    providerType: selectedBundle?.providerType || '',
    size: String(selectedBundle?.dataAmount || ''),
  })

  useEffect(() => {
    if (regionById) {
      setSelectedBundle(Object.values(regionById.bundles).flat()[0]);
    }
  }, [regionById])

  const sendPurchaseEvents = useCallback(() => {
    sendSafeGtagEvent('buy_button_click', {
      region: region.name,
      dataAmount: selectedBundle?.dataAmount,
      name: selectedBundle?.name
    })

    sendSafeMixpanelEvent('track', 'buy_button_click', {
      region: region.name,
      dataAmount: selectedBundle?.dataAmount,
      name: selectedBundle?.name
    })

    sendSafeFbqEvent('InitiateCheckout')
  }, [sendSafeGtagEvent, sendSafeMixpanelEvent, sendSafeFbqEvent, selectedBundle, region])

  return (
    loading || isRegionByIdLoading
      ? <SC.LoaderWrapper > <Loader /></SC.LoaderWrapper >
      : (
        <BaseRegionCard
          region={region}
          AdditionalInfoSection={
            <>
              <SC.BundleCardsWrapper>
                {regionById && Object.values(regionById.bundles)
                  .flat()
                  .map((bundle) => (
                    <BundleInfo
                      selected={selectedBundle?.paymentCode === bundle.paymentCode}
                      key={bundle.paymentCode}
                      onClick={() => setSelectedBundle(bundle)}
                      {...bundle}
                      embeddedHeader={
                        <CoverageCountriesOpenModalButton
                          onClick={() => {
                            showBundleCoverageCountries(bundle)
                          }}
                        />
                      }
                    />
                  ))}
              </SC.BundleCardsWrapper>
              <Button
                onClick={sendPurchaseEvents}
                fullWidth
                label={<LocaleLink href={`/details?${search.toString()}`}>{t('buy')}</LocaleLink>}
              />
            </>
          }
        />
      )
  )
}

export { RegionalEsim };
