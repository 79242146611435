import styled, { css } from 'styled-components';

export const SwitchButton = styled.button<{
  $isSelected?: boolean;
}>`
  white-space: nowrap;
  flex: 1;
  cursor: pointer;
  z-index: 2;
  padding: 6px 24px;
  position: relative;
  border-radius: 5px;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  border: none;
  background: ${(props) => (props.theme.switchButtons[props.$isSelected ? 'activeBg' : 'defaultBg'])};
  color: ${(props) => props.theme.primaryText};
  transition: 0.3s color;

  > div:first-child {
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 5px;
    text-transform: capitalize;
    position: relative;
    z-index: 2;
  }

  @media (max-width: 920px) {
    width: 100%;
  }
`;

export const Wrapper = styled.div<{
  $isOpen?: boolean;
  $isStyledAsDropdown?: boolean;
}>`
  display: flex;
  grid-gap: 20px;
  padding: 4px;
  background: ${(props) => props.theme.switchButtons.defaultBg};
  border-radius: 10px;
  border: 1px solid ${(props) => props.theme.switchButtons.border};

  @media (max-width: 920px) {
    grid-gap: 0;
  }

  @media (max-width: 768px) {
    ${(props) =>
    props.$isStyledAsDropdown &&
    css`
        width: 100% !important;
        flex-direction: column;
        max-height: 50px;
        overflow: hidden;

        ${SwitchButton} {
          display: flex;
          align-items: center;
          justify-content: space-between;
          grid-gap: 20px;

          &:first-child {
            margin-bottom: 5px;
            svg {
              transition: 0.3s all var(--easing-func);
              transform: rotate(${props.$isOpen ? '-90deg' : '90deg'});

              * {
                fill: #ffffff;
              }
            }
          }
        }
      `}
  }
`;
