import React, { useCallback } from 'react';
import { useTranslations } from 'next-intl';
import { useAnalyticsContext } from '@/context/AnalyticsContext';
import LocaleLink from '@/entities/LocaleLink';
import { getCurrencySymbol } from '@/shared/lib';
import { Bundle } from '@/utils/types';
import Button from '@/shared/ui/Button';

function GetPlanButton({ providerType, dataAmount, currency, price, isoName2, name, worldwide }: Bundle) {
  const t = useTranslations();
  const { sendSafeMixpanelEvent, sendSafeFbqEvent, sendSafeGtagEvent } = useAnalyticsContext();

  const search = new URLSearchParams({
    country: isoName2,
    providerType,
    size: String(dataAmount),
  })

  const sendPurchaseEvents = useCallback(() => {
    sendSafeGtagEvent('buy_button_click', {
      worldwide: worldwide ? 'worldwide' : '',
      dataAmount,
      name
    })

    sendSafeMixpanelEvent('track', 'buy_button_click', {
      worldwide: worldwide ? 'worldwide' : '',
      dataAmount,
      name
    })

    sendSafeFbqEvent('InitiateCheckout')
  }, [sendSafeGtagEvent, sendSafeMixpanelEvent, sendSafeFbqEvent, dataAmount, worldwide, name])

  return (
    <Button
      onClick={sendPurchaseEvents}
      fullWidth
      variant="secondary"
      label={<LocaleLink href={`/details?${search.toString()}`}>{t('buy_a_plan_for')} {getCurrencySymbol(currency)}{price}</LocaleLink>}
    />
  );
}

export { GetPlanButton };