import styled from 'styled-components';

export const Wrapper = styled.div``

export const ContentWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 15px;
`

export const CountryCard = styled.div <{ $selected: boolean }>`
  padding: 30px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 15px;
  cursor: pointer;
  background: ${(props) => props.theme.translucentCardsBg};
  border: 1px solid ${(props) => props.$selected ? props.theme.primary : props.theme.translucentCardsBg};
  border-radius: 10px;

  > div {
    text-align: center;
  }
`;

export const ActiveCountryWrapper = styled.div`
  padding: 25px 10px 10px;
  border-radius: 10px;
  background: ${(props) => props.theme.translucentCardsBg};
  grid-column-start: 1;
  grid-column-end: 3;
`

export const SeeAllSection = styled.div`
  margin-top: 20px;
  width: 100%;
  padding: 15px;
  border-radius: 10px;
  border: 1px solid ${(props) => props.theme.primaryText};
  color: ${(props) => props.theme.primaryText};
  text-align: center;
  cursor: pointer;
`;

export const BundlesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  grid-gap: 12px;
  margin-bottom: 12px;
`;

export const LeftSideWrapper = styled.div`
  flex: 0 1 60%;

  @media (max-width: 768px) {
    flex: 1 1 100%;
  }
`

export const InfoSection = styled.div`
  padding: 50px 25px 25px;
  border-radius: 10px;
  background: ${(props) => props.theme.translucentCardsBg};
  flex: 0 1 40%;
`

export const CountriesCards = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;

  @media (max-width: 1023px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const DataAmountsWrapper = styled.div`
  margin: 50px 0;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  button {
    width: 80px;
    font-size: 14px;
    font-weight: 600;
  }

  @media (max-width: 768px) {
    margin: 25px 0;
    justify-content: center
  }
`

export const BundleCardsWrapper = styled.div`
  width: 100%;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  gap: 5px;
`

export const LoaderWrapper = styled.div`
  height: 500px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`
