'use client'

import { useTheme } from "styled-components";
import { useAnalyticsContext } from "@/context/AnalyticsContext";
import Icon from '@/shared/ui/Icon';
import { ONE_LINK } from '@/shared/constants';
import {
  GPlayIcon,
  GPlayBlackIcon,
  AppstoreIcon,
  AppstoreBlackIcon,
} from '@/shared/ui/Icon/svg';
import * as SC from './styled';

function Buttons() {
  const theme = useTheme();
  const { sendSafeGtagEvent, sendSafeMixpanelEvent } = useAnalyticsContext();

  const handleMarketClick = () => {
    sendSafeGtagEvent('onelink_click');
    sendSafeMixpanelEvent('track', 'onelink_click');
  };
  return (
    <SC.LinksWrapper>
      <a target="_blank" rel="noreferrer" href={ONE_LINK} onClick={handleMarketClick}>
        <Icon
          width={120}
          height={40}
          component={(theme as any).name === 'light' ? GPlayIcon : GPlayBlackIcon}
        />
      </a>
      <a target="_blank" rel="noreferrer" href={ONE_LINK} onClick={handleMarketClick}>
        <Icon
          width={120}
          height={40}
          component={(theme as any).name === 'light' ? AppstoreIcon : AppstoreBlackIcon}
        />
      </a>
    </SC.LinksWrapper>
  )
}

export default Buttons;