import styled from 'styled-components';

export const Wrapper = styled.div``;

export const ContentWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 15px;

  @media (max-width: 1023px) {
    flex-direction: column;
  }
`

export const RegionsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;

  @media (max-width: 1023px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const RegionCard = styled.div <{ $selected: boolean }>`
  padding: 30px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 15px;
  cursor: pointer;
  background: ${(props) => props.theme.translucentCardsBg};
  border: 1px solid ${(props) => props.$selected ? props.theme.primary : props.theme.translucentCardsBg};
  border-radius: 10px;
`;

export const LeftSideWrapper = styled.div`
  flex: 0 1 60%;

  @media (max-width: 1023px) {
    flex: auto;
    width: 100%;
  }
`

export const InfoSection = styled.div`
  padding: 50px 25px 25px;
  border-radius: 10px;
  background: ${(props) => props.theme.translucentCardsBg};
  flex: 0 1 40%;

  @media (max-width: 1023px) {
    flex: auto;
    width: 100%;
  }
`

export const BundleCardsWrapper = styled.div`
  width: 100%;
  margin: 50px 0 30px;
  display: flex;
  flex-direction: column;
  gap: 5px;

  @media (max-width: 1023px) {
    margin: 30px 0;
  }
`

export const LoaderWrapper = styled.div`
  height: 500px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`