'use client';

import React from 'react';
import { useTranslations } from 'next-intl';
import * as SC from './styled';

function Header() {
  const t = useTranslations();

  return (
    <SC.Wrapper>
      <SC.Title>{t.rich('two_in_one', {
        gradient: (chunk) => (
          <SC.GradientTextWrapper>
            <SC.GradientText>{chunk}</SC.GradientText>
          </SC.GradientTextWrapper>
        )
      })}</SC.Title>
      <SC.SectionDescription>{t('mobile_data_subtitle')}</SC.SectionDescription>
    </SC.Wrapper>
  );
}

export { Header };
