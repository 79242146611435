import { Container } from '@/shared/ui/styled';
import styled from 'styled-components';

export const Wrapper = styled(Container)`
  padding: 0;
  margin: 50px auto 25px;
`

export const Title = styled.h1`
  margin: 0 16px;
  color: ${(props) => props.theme.primaryText};
  text-align: center;
  font-size: 48px;
  line-height: 1.3;
  letter-spacing: -1.7px;
  font-weight: 700;

  @media (max-width: 768px) {
    font-size: 40px;
  }
`;

export const GradientTextWrapper = styled.div`
  display: inline-flex;
  width: max-content;

  @media (max-width: 768px) {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    height: 60px;
  }
`

export const GradientText = styled.span`
  position: relative;
  padding: 20px;
  width: max-content;
  transform: rotate(-5deg);
  background: linear-gradient(83deg, #94CF3D -2.64%, #08C3EC 51.65%, #3C2BDD 94.96%);;
  border-radius: 25px;
  border: 5px solid ${(props) => props.theme.htmlBg};
  color: #FFF;
  white-space: nowrap;
  line-height: 1;

  @media (max-width: 768px) {
    padding: 15px;
  }
`

export const SectionDescription = styled.p`
  margin: 16px;
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${(props) => props.theme.secondaryText};
  margin: 5px 16px 0;
`


