import React from 'react';
import { Bundle, RegionById } from 'utils/types';
import { BundleCard } from '@/entities/bundle';
import GetPlanButton from '@/entities/GetPlanButton';
import CoverageCountriesOpenModalButton from '@/entities/CoverageCountriesOpenModalButton';
import * as SC from './styled';

type Props = { worldwideRegion?: RegionById, showBundleCoverageCountries: (bundle: Bundle) => void }

function GlobalEsim({ worldwideRegion, showBundleCoverageCountries }: Props) {

  return (
    <SC.BundlesWrapper>
      {worldwideRegion &&
        Object.values(worldwideRegion.bundles)
          .flat()
          .map((bundle) => (
            <BundleCard
              key={bundle.price}
              title="Worldwide"
              duration={bundle.duration}
              img={bundle.image}
              worldwide={bundle.worldwide}
              supportedCountries={bundle.countries}
              style={{ flex: '1 1 350px' }}
              embeddedHeader={
                <CoverageCountriesOpenModalButton
                  onClick={() => {
                    showBundleCoverageCountries(bundle)
                  }}
                />
              }
              embeddedFooter={<GetPlanButton {...bundle} />}
            />
          ))}
    </SC.BundlesWrapper>
  );
}

export { GlobalEsim };
