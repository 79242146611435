'use client';

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslations } from 'next-intl';
import { useQuery } from '@tanstack/react-query';
import { usePathname, useSearchParams, useRouter } from 'next/navigation';
import api from '@/api';
import type { Bundle, Country } from '@/utils/types';
import { createQueryString } from '@/utils/common';
import { useAnalyticsContext } from '@/context/AnalyticsContext';
import { useWindowSize } from '@/context/WindowSizeContext';
import { BundleInfo } from '@/entities/bundle';
import LocaleLink from '@/entities/LocaleLink';
import CoverageCountriesOpenModalButton from '@/entities/CoverageCountriesOpenModalButton';
import BaseCountryCard from '@/entities/CountryCard';
import { formatDataSize, scrollToId } from '@/shared/lib';
import { DEFAULT_SELECTED_DATA_SIZE, SectionIDS } from '@/shared/constants';
import CountryFlag from '@/shared/ui/CountryFlag';
import Button from '@/shared/ui/Button';
import Loader from '@/shared/ui/Loader';
import { NoMatchesText } from '@/shared/ui/styled';
import * as SC from './styled';

type Props = { countries: Country[], filter: string, showBundleCoverageCountries: (bundle: Bundle) => void };

type CountryCardProps = {
  country: Country;
  showBundleCoverageCountries: (bundle: Bundle) => void
}

const getLastInRowIndex = ({ fromIndex, countInRow }: { fromIndex: number; countInRow: number }) => {
  const rest = (fromIndex + 1) % countInRow;
  return rest ? fromIndex + 1 - rest + countInRow - 1 : fromIndex
}

const PREVIEW_COUNTRIES_COUNT = 12;

function LocalEsim({ countries, showBundleCoverageCountries, filter }: Props) {
  const router = useRouter();
  const { sendSafeGtagEvent, sendSafeMixpanelEvent } = useAnalyticsContext();
  const searchParams = useSearchParams();
  const pathname = usePathname();
  const t = useTranslations();
  const { isMobile } = useWindowSize();
  const [isViewingAll, setIsViewingAll] = React.useState(false);
  const country = searchParams.get('country');
  const activeCountryIndex = useMemo(() => {
    const index = countries.findIndex((el) => el.isoName2.toLowerCase() === country);
    return index < 0 && !isMobile ? 0 : index;
  }, [countries, country, isMobile]);
  const activeCountry = countries[activeCountryIndex];
  const sectionRef = React.useRef<HTMLDivElement | null>(null);

  const [firtsPartFilteredCountries, secondPartFilteredCountries] = useMemo(() => {
    if (activeCountryIndex < 0 || !isMobile) {
      return [countries]
    }
    const countInRow = isMobile ? 2 : 3;
    const lastInRowIndex = getLastInRowIndex({ fromIndex: activeCountryIndex, countInRow }) || countries.length - 1;

    return [countries.slice(0, lastInRowIndex + 1), countries.slice(lastInRowIndex + 1, countries.length)]

  }, [countries, activeCountryIndex, isMobile])


  const changeActiveCountry = useCallback((_country: Country | null) => {
    sendSafeGtagEvent('click_choose_country', { country: _country?.country })
    sendSafeMixpanelEvent('track', 'click_choose_country', { country: _country?.country })
    const currentParams = new URLSearchParams(searchParams.toString());
    currentParams.delete("country");
    const queryString = _country
      ? createQueryString('country', _country?.isoName2.toLowerCase(), currentParams)
      : currentParams.toString();
    router.replace(`${pathname}${queryString.length > 0 ? `?${queryString}` : ''}`, { scroll: false });

  }, [router, searchParams, pathname, sendSafeGtagEvent, sendSafeMixpanelEvent]);

  useEffect(() => {
    if (activeCountry) {
      if (isMobile) {
        scrollToId(activeCountry.country, 65);
      } else if (activeCountryIndex > PREVIEW_COUNTRIES_COUNT - 1) {
        scrollToId(SectionIDS.InfoSection, 165)
      }
    }
  }, [activeCountry, activeCountryIndex, isMobile])

  useEffect(() => {
    if (activeCountry) {
      const newActiveCountry = countries.find(({ isoName2 }) => isoName2 === activeCountry?.isoName2);

      if (!newActiveCountry) {
        changeActiveCountry(null)
      }
    }
  }, [activeCountry, countries, changeActiveCountry]);

  return (
    <SC.Wrapper>
      <SC.ContentWrapper>
        <SC.LeftSideWrapper>
          <SC.CountriesCards ref={sectionRef}>
            {firtsPartFilteredCountries
              .slice(0, activeCountryIndex > PREVIEW_COUNTRIES_COUNT - 1 || isViewingAll ? firtsPartFilteredCountries.length : PREVIEW_COUNTRIES_COUNT)
              .map((countryData) => (
                <SC.CountryCard
                  id={countryData.country}
                  key={countryData.country}
                  onClick={() => changeActiveCountry(countryData)}
                  $selected={countryData.isoName2 === activeCountry?.isoName2}
                >
                  <div>
                    <CountryFlag height={42} width={56} name={countryData.isoName2} />
                  </div>
                  <div>{countryData.country}</div>
                </SC.CountryCard>
              ))}
            {isMobile && activeCountry && (
              <SC.ActiveCountryWrapper>
                <CountryCard
                  country={activeCountry}
                  showBundleCoverageCountries={showBundleCoverageCountries}
                />
              </SC.ActiveCountryWrapper>
            )}
            {secondPartFilteredCountries
              && secondPartFilteredCountries
                .slice(0, firtsPartFilteredCountries.length <= PREVIEW_COUNTRIES_COUNT && !isViewingAll ? PREVIEW_COUNTRIES_COUNT - firtsPartFilteredCountries.length : secondPartFilteredCountries.length)
                .map((countryData) => (
                  <SC.CountryCard
                    id={countryData.country}
                    key={countryData.country}
                    onClick={() => changeActiveCountry(countryData)}
                    $selected={countryData.isoName2 === activeCountry?.isoName2}
                  >
                    <div>
                      <CountryFlag height={42} width={56} name={countryData.isoName2} />
                    </div>
                    <div>{countryData.country}</div>
                  </SC.CountryCard>
                ))}
          </SC.CountriesCards>
          {!isViewingAll && countries.length > PREVIEW_COUNTRIES_COUNT && activeCountryIndex < PREVIEW_COUNTRIES_COUNT && (
            <SC.SeeAllSection onClick={() => setIsViewingAll(true)}>
              See All
            </SC.SeeAllSection>
          )}
        </SC.LeftSideWrapper>
        {!isMobile && activeCountry && (
          <SC.InfoSection id={SectionIDS.InfoSection}>
            <CountryCard
              country={activeCountry}
              showBundleCoverageCountries={showBundleCoverageCountries}
            />
          </SC.InfoSection>
        )}
      </SC.ContentWrapper>
      {!countries.length && (
        <NoMatchesText>{t('countries_not_found_by_filter', { filter })}</NoMatchesText>
      )}
    </SC.Wrapper>
  );
}

function CountryCard({ country, showBundleCoverageCountries }: CountryCardProps) {
  const t = useTranslations()
  const { sendSafeGtagEvent, sendSafeFbqEvent, sendSafeMixpanelEvent } = useAnalyticsContext();
  const [selectedDataSize, setSelectedDataSize] = useState(DEFAULT_SELECTED_DATA_SIZE);
  const [bundlesByDataAmount, setBundlesByDataAmount] = useState<Bundle[]>();
  const [selectedBundle, setSelectedBundle] = useState(bundlesByDataAmount?.[0]);

  const { data: countryByIsoName, isLoading } = useQuery(
    ['country-by-iso', country?.isoName2],
    async () => {
      const { data } = await api.profiles.getCountryByIsoName(country.isoName2);
      return data?.data.country;
    },
    { enabled: Boolean(country?.isoName2) }
  );

  const search = new URLSearchParams({
    country: selectedBundle?.isoName2 || '',
    providerType: selectedBundle?.providerType || '',
    size: String(selectedBundle?.dataAmount || ''),
  })

  useEffect(
    function updateBundlesByDataAmount() {
      if (!countryByIsoName) return;
      const existingBundlesBySelectedDataAmount = countryByIsoName.bundles[selectedDataSize] as Bundle[] | undefined;

      // update the data size if there is no match for the currently selected data size
      if (!existingBundlesBySelectedDataAmount) {
        setSelectedDataSize(countryByIsoName.availableDataAmounts[0]);
      }

      setBundlesByDataAmount(
        existingBundlesBySelectedDataAmount ?? countryByIsoName.bundles[countryByIsoName.availableDataAmounts[0]]
      );
    },
    [countryByIsoName, selectedDataSize]
  );

  useEffect(() => {
    setSelectedBundle(bundlesByDataAmount?.[0]);
  }, [bundlesByDataAmount])

  const sendPurchaseEvents = useCallback(() => {
    sendSafeGtagEvent('buy_button_click', {
      country: country.country,
      dataAmount: selectedBundle?.dataAmount,
      name: selectedBundle?.name
    })

    sendSafeMixpanelEvent('track', 'buy_button_click', {
      country: country.country,
      dataAmount: selectedBundle?.dataAmount,
      name: selectedBundle?.name
    })

    sendSafeFbqEvent('InitiateCheckout')
  }, [sendSafeGtagEvent, sendSafeMixpanelEvent, sendSafeFbqEvent, selectedBundle, country])

  return (
    <BaseCountryCard
      country={country}
      AdditionalInfoSection={
        isLoading ? (
          <SC.LoaderWrapper > <Loader /></SC.LoaderWrapper >
        ) : (
          <>
            <SC.DataAmountsWrapper>
              {countryByIsoName?.availableDataAmounts?.map((size) => (
                <Button
                  key={size}
                  variant={selectedDataSize === size ? 'secondary' : 'outlined'}
                  size='small'
                  label={formatDataSize(size)}
                  onClick={() => setSelectedDataSize(size)}
                />
              ))}
            </SC.DataAmountsWrapper>
            <SC.BundleCardsWrapper>
              {bundlesByDataAmount
                ?.sort((prevBundle, nextBundle) => prevBundle.price - nextBundle.price)
                .map((bundle) => (
                  <BundleInfo
                    selected={selectedBundle?.paymentCode === bundle.paymentCode}
                    key={bundle.paymentCode}
                    onClick={() => setSelectedBundle(bundle)}
                    {...bundle}
                    embeddedHeader={
                      <CoverageCountriesOpenModalButton
                        onClick={() => {
                          showBundleCoverageCountries(bundle)
                        }}
                      />
                    }
                  />
                ))}
            </SC.BundleCardsWrapper>
            <Button
              onClick={sendPurchaseEvents}
              fullWidth
              label={<LocaleLink href={`/details?${search.toString()}`}>{t('buy')}</LocaleLink>}
            />
          </>
        )}
    />
  )
}

export { LocalEsim };
