'use client'

import { PropsWithChildren } from 'react';
import { useTranslations } from 'next-intl';
import { DownloadAppButtons, DownloadAppQRCode } from '@/entities/download-app';
import * as SC from './styled';

function DownloadAppSideSection({ children }: PropsWithChildren) {
  const t = useTranslations();

  return (
    <SC.Wrapper>
      {children}
      <SC.SideSectionWrapper>
        <SC.Title>{t('download_an_app')}</SC.Title>
        <SC.QRCodeWrapper>
          <DownloadAppQRCode />
        </SC.QRCodeWrapper>
        <SC.ButtonsWrapper>
          <DownloadAppButtons />
        </SC.ButtonsWrapper>
      </SC.SideSectionWrapper>
    </SC.Wrapper>
  )
}

export default DownloadAppSideSection;