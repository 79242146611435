import styled from 'styled-components';
import LocaleLink from '@/entities/LocaleLink';
import { Container } from '@/shared/ui/styled';

export const SelectedBg = styled.div`
  background: ${(props) => props.theme.primary};
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 8px;
  z-index: 1;
  color: white;
`;

export const ControlsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 50px 0;
`

export const SearchInputWrapper = styled.div`
  width: 100%;
  max-width: 456px;
  margin-bottom: 15px;
`

export const SwitchButton = styled.button<{ $isSelected?: boolean }>`
  cursor: pointer;
  z-index: 2;
  padding: 12px;
  position: relative;
  border-radius: 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  border: none;
  background: transparent;
  color: ${(props) => (props.$isSelected ? 'white' : '#777E90')};
  transition: 0.3s color;

  > div:first-child {
    text-transform: capitalize;
    position: relative;
    z-index: 2;
  }

  @media (max-width: 920px) {
    width: 100%;
  }

  @media (max-width: 768px) {
    display: flex;
    grid-gap: 5px;
    align-items: center;
    justify-content: flex-start;
    color: ${(props) => (props.$isSelected ? props.theme.primaryText : props.theme.secondaryText)};

    &:first-child {
      > div:first-child {
        border-right: 1px solid ${(props) => props.theme.borderColor};
      }
    }

    > div:first-child {
      text-align: left;
      width: 100%;
    }
  }
`;

export const ButtonsWrapper = styled.div<{ isOpen?: boolean }>`
  display: flex;
  grid-gap: 20px;
  padding: 5px;
  background: ${(props) => props.theme.cardsBg};
  box-shadow: 0 5px 100px rgba(0, 0, 0, 0.1);
  border-radius: 12px;

  @media (max-width: 920px) {
    grid-gap: 0;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    max-height: ${(props) => (props.isOpen ? '130px' : '50px')};
    overflow: hidden;
  }
`;

export const Title = styled.h2`
  margin: 0 auto 50px;
  font-size: 32px;
  font-weight: 700;
  text-align: center;
  color: ${(props) => props.theme.primaryText};
`;

export const Wrapper = styled(Container)`
  margin-bottom: 150px;

  @media (max-width: 768px) {
    margin-bottom: 100px;
  }
`;

export const PupularCountries = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 50px;
`

export const PupularCountryWrapper = styled(LocaleLink)`
  display: flex;
  align-items: center;
  gap: 3px;
  text-decoration: underline;
  color: ${(props) => props.theme.primaryText};
  font-size: 14px;
  font-weight: 400;
`