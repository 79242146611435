'use client';

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocale, useTranslations } from 'next-intl';
import { toast } from 'react-hot-toast';
import { useDebounce } from 'use-debounce';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import api from '@/api';
import { createQueryString } from '@/utils/common';
import { Bundle, Country, Region, RegionById } from '@/utils/types';
import { useWindowSize } from '@/context/WindowSizeContext';
import { CoverageCountries } from '@/features/coverage-countries';
import RegionalEsimPlans from '@/features/RegionalEsim';
import LocalEsimPlans from '@/features/LocalEsim';
import GlobalEsimPlans from '@/features/GlobalEsim';
import SwitchButtons from '@/entities/SwitchButtons';
import { getErrorMessage } from '@/shared/lib';
import { SectionIDS } from '@/shared/constants';
import { useModalControls } from '@/shared/hooks';
import SearchInput from '@/shared/ui/SearchInput';
import CountryFlag from '@/shared/ui/CountryFlag';

import * as SC from './styled';

enum Regions {
  Local = 'local_esim',
  Regional = 'regional_esim',
  Global = 'global_esim',
}

const popularList = [
  {
    src: 'https://static.esimplus.net/storage/plans/130x80/Europe350.jpg',
    name: 'Europe',
    href: '/?region=regional_esim&regional=europe',
  },
  {
    src: 'https://static.esimplus.net/storage/flags/ae.svg',
    name: 'UAE',
    href: '/?region=local_esim&country=ae',
  },
  {
    src: 'https://static.esimplus.net/storage/flags/us.svg',
    name: 'United States',
    href: '/?region=local_esim&country=us',
  }
]

const getSelectedRegion = (region: string | Region | null) => {
  if (region === Regions.Local) {
    return Regions.Local
  }

  if (region === Regions.Global) {
    return Regions.Global
  }

  return Regions.Regional
}

function AvailableMobileDataPlans({
  countries,
  regions,
  worldwideRegion,
}: {
  countries: Country[];
  regions: Region[];
  worldwideRegion?: RegionById;
}) {
  const router = useRouter();
  const pathname = usePathname();
  const locale = useLocale();
  const searchParams = useSearchParams();
  const { isMobile } = useWindowSize();
  const t = useTranslations();
  const [filterText, setFilterText] = useState<string>('');
  const [filteredCountries, setFilteredCountries] = useState<Country[]>(countries);
  const [isFilteredCountriesLoading, setIsFilteredCountriesLoading] = useState(false);
  const [selectedBundle, setSelectedBundle] = useState<Bundle>();
  const [debouncedFilterText] = useDebounce(filterText, 500);

  const region = searchParams.get('region');
  const selectedRegion = getSelectedRegion(region)

  const {
    isOpen: isCoverageCountriesModalOpen,
    closeModal: closeCoverageCountriesModal,
    openModal: openCoverageCountriesModal,
  } = useModalControls(false, { disableBodyScroll: true });

  const showBundleCoverageCountries = useCallback((bundle: Bundle) => {
    setSelectedBundle(bundle);
    openCoverageCountriesModal()
  }, [openCoverageCountriesModal])

  const hideBundleCoverageCountries = useCallback(() => {
    closeCoverageCountriesModal();
    setSelectedBundle(undefined);
  }, [closeCoverageCountriesModal])

  useEffect(() => {
    if (debouncedFilterText) {
      setIsFilteredCountriesLoading(true)
      api.profiles
        .getCountriesByFilterText(debouncedFilterText, locale)
        .then(({ data }) => {
          setFilteredCountries(data?.data ?? []);
        })
        .catch((e: unknown) => {
          toast.error(getErrorMessage(e));
        })
        .finally(() => {
          setIsFilteredCountriesLoading(false)
        })
    } else {
      setFilteredCountries(countries);
    }
    // filteredCountries in depth will create a recursive call
  }, [locale, debouncedFilterText, countries]);

  const regionOptions = useMemo(() => (
    Object.values(Regions).map((val) => ({
      label: t(val),
      value: val,
    }))
  ), [t])

  const onChangeRegion = ({ value }: { label: string, value: Regions }) => {
    const current = new URLSearchParams(searchParams.toString());
    router.replace(`${pathname}?${createQueryString('region', value.toLowerCase(), current)}`, { scroll: false });
  }

  return (
    <>
      <SC.Wrapper id={SectionIDS.SearchYourDestination}>
        <SC.Title>
          {t('available_plans')}
        </SC.Title>
        <SC.ControlsWrapper>
          {selectedRegion !== Regions.Global && (
            <SC.SearchInputWrapper>
              <SearchInput
                placeholder={t('search_by_country')}
                onChange={(e) => {
                  setFilterText(e.currentTarget.value.replaceAll(/[/|\\]/g, ''));
                }}
                value={filterText}
              />
            </SC.SearchInputWrapper>
          )}

          <SC.PupularCountries>
            <span>Popular:</span>
            {popularList.map((country) => (
              <SC.PupularCountryWrapper href={country.href} key={country.name} scroll={false}>
                <CountryFlag width={20} height={15} src={country.src} />
                {country.name}
              </SC.PupularCountryWrapper>
            ))}
          </SC.PupularCountries>
          <SwitchButtons
            value={{ label: t(selectedRegion), value: selectedRegion }}
            onChange={onChangeRegion}
            options={regionOptions}
            styledAsDropdown={isMobile}
          />
        </SC.ControlsWrapper>
        {selectedRegion === Regions.Local && <LocalEsimPlans showBundleCoverageCountries={showBundleCoverageCountries} filter={debouncedFilterText} countries={filteredCountries} />}
        {selectedRegion === Regions.Regional && <RegionalEsimPlans showBundleCoverageCountries={showBundleCoverageCountries} isCountriesLoading={isFilteredCountriesLoading} filter={debouncedFilterText} regions={regions} countries={filteredCountries} />}
        {selectedRegion === Regions.Global && <GlobalEsimPlans showBundleCoverageCountries={showBundleCoverageCountries} worldwideRegion={worldwideRegion} />}
      </SC.Wrapper>
      {
        isCoverageCountriesModalOpen && selectedBundle && (
          <CoverageCountries
            worldwide={Boolean(selectedBundle.worldwide)}
            supportedCountries={selectedBundle.countries}
            modalControllers={{
              isOpen: isCoverageCountriesModalOpen,
              onClose: hideBundleCoverageCountries
            }}
          />
        )
      }
    </>
  );
}

export { AvailableMobileDataPlans };
