import { ReactElement } from "react";
import type { Region } from '@/utils/types';
import { BASE_STORAGE_URL } from "@/shared/constants"
import CountryFlag from "@/shared/ui/CountryFlag";
import * as SC from './styled';

type Props = {
  region: Region;
  AdditionalInfoSection?: ReactElement;
}

function RegionCard({ region, AdditionalInfoSection }: Props) {

  return (
    <SC.Wrapper>
      <div>
        <CountryFlag width={56} height={42} src={`${BASE_STORAGE_URL}130x80/${region.name}350.jpg`} />
      </div>
      <SC.RegionName>{region.name}</SC.RegionName>
      {AdditionalInfoSection}
    </SC.Wrapper>
  )
}

export default RegionCard