import styled from "styled-components";

export const LinksWrapper = styled.div`
  display: flex;
  width: 100%;
  grid-gap: 12px;

  > a {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: ${(props) => (props.theme.name === 'light' ? '#ffffff' : '#000000')};
    width: fit-content;
    border: none;
    border-radius: 6px;
    background: ${(props) => (props.theme.name === 'light' ? '#000000' : '#ffffff')};

    svg {
      max-width: 160px;
      width: 100%;
      height: 45px;
    }
  }
`;